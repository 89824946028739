import React  from 'react';
import Section from "../../components/section/Section";

const Footer = () => {
    return (
        <Section id="footer" className="Footer" sectionName="footer" variant="dark">

        </Section>
    )
}
export default Footer;